<template>
  <div class="fun-bar">
    <div class="card-title">
      {{ title }}
    </div>
    <div class="card-func-desc">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped lang="scss">
.fun-bar{
  width: 88%;
  margin: 0 auto;
}
.card-title {
  font-size: 29px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  position: relative;
  padding-bottom: 16px;
}

.card-title:after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: linear-gradient(137deg, #E266FF 0%, #00FCFF 100%);
  border-radius: 4px;
}

.card-func-desc {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 57px;
  line-height: 33px;
}

</style>
