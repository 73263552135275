<template>
  <div class="bg-card wow fadeInDown" >
    <div class="zy-container">
      <FuncTitle :title="funcData[funcIndex].title" :subTitle="funcData[funcIndex].subTitle"/>
      <el-carousel indicator-position="none" height="380px" @change="onCarousel" :autoplay="false">
        <el-carousel-item v-for="(item,index) in funcData" :key="index" class="carousel-item">
          <el-image :src="item.carouseImg" class="carousel-img"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import FuncTitle from './func-title'
import {WOW} from "wowjs";

export default {
  components: {
    FuncTitle
  },
  props: {
    funcData: Object,
  },
  data() {
    return {
      funcIndex: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      new WOW().init()
    })
  },
  methods: {
    onCarousel(index) {
      this.funcIndex = index
    }
  }
}
</script>

<style scoped lang="scss">

.bg-card {
  background: #F8F8FC;
  padding-bottom: 85px;
  padding-top: 100px;
}

.carousel-item {
  .carousel-img {
    width: 750px;
    display: block;
    margin: 0 auto;
    height: 380px;
  }
}

</style>
