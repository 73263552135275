<template>
  <div class="bg-card">
    <div class="zy-container">
      <FuncTitle :title="stepData.title" :subTitle="stepData.subTitle"/>
      <div class="steps" v-if="stepData.stepList.length>1">
        <div class="step-col" v-for="(item,index) in stepData.stepList" :key="index">
          <div class="step-head">
            <div class="step-line"></div>
            <div class="step-icon" @mouseover="onMouseOver(index)">
              <el-image :src="index===stepIndex?item.choiceImg:item.normalImg" alt="" class="step-img"/>
            </div>
          </div>
          <div class="step-main" @mouseover="onMouseOver(index)">
            <div class="step-title" :style="{color:index===stepIndex?'#2935A7':'#3B3A40'}">{{ item.stepTitle }}</div>
          </div>
        </div>
      </div>
      <div class="show-how">
        <el-image :src="require('@/assets/images/index/step-bg.png')" fit="scale-down"/>
        <transition name="slide-fade">
          <el-image :src="stepData.stepList[stepIndex].showImg" class="show-how-img"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import FuncTitle from './func-title'

export default {
  components: {
    FuncTitle
  },
  props: {
    stepData: Object,
  },
  data() {
    return {
      stepIndex: 0,
    }
  },
  methods: {
    onMouseOver(index) {
      this.stepIndex = index
    }
  }
}
</script>

<style scoped lang="scss">


.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .5s;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}

.bg-card {
  background: #F8F8FC;
  padding-bottom: 85px;
  padding-top: 100px;
}

//.card-title {
//  font-size: 29px;
//  font-weight: 600;
//  color: #333333;
//  text-align: center;
//  position: relative;
//  padding-bottom: 16px;
//}
//
//.card-title:after {
//  content: '';
//  display: block;
//  width: 50px;
//  height: 4px;
//  position: absolute;
//  left: 50%;
//  bottom: 0;
//  transform: translateX(-50%);
//  background: linear-gradient(137deg, #E266FF 0%, #00FCFF 100%);
//  border-radius: 4px;
//}
//
//.card-func-desc {
//  font-size: 14px;
//  font-weight: 400;
//  color: #999999;
//  text-align: center;
//  padding-top: 25px;
//  padding-bottom: 57px;
//}

.steps {
  display: flex;
  white-space: nowrap;
  padding-left: 12%;
  padding-right: 12%;
  margin-bottom: 24px;

  .step-col {
    display: inline-block;
    flex-basis: 50%;
    position: relative;
    flex-shrink: 1;
  }


  .step-col:last-of-type {
    flex-basis: auto !important;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .step-head {
    position: relative;
    width: 100%;

    .step-line {
      position: absolute;
      border-color: inherit;
      background-color: #E8E7E8;
      height: 1px;
      top: 29px;
      left: 0;
      right: 0;
      width: 100%;
    }

    .step-icon {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 54px;
      font-size: 14px;
      box-sizing: border-box;
      background: #F8F8FC;
      transition: .15s ease-out;
      cursor: pointer;
    }

    .step-img {
      width: 54px;
      height: 54px;
    }
  }


  .step-main {
    white-space: normal;
    text-align: center;
    width: 54px;
    cursor: pointer;

    .step-title {
      font-size: 13px;
      font-weight: 500;
      color: #3B3A40;
      line-height: 14px;
    }
  }
}


.show-how {
  position: relative;

  .show-how-img {
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
