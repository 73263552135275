<template>
  <div style="overflow-x: hidden;background-color: white;position: absolute;left: 0;right: 0;top: 0" class="bg">
    <Navigator/>
    <SliderBanner :slider="slider"/>
    <Step :stepData="stepInfo"/>
    <FuncShow :funInfo="funInfo" style="background: #F4F7FF"/>
    <FuncBanner :funcData="funcData" style="background: #F4F7FF"/>
    <Footer/>
    <ContactFloat/>
  </div>
</template>

<script>
import Navigator from '../widgets/navigator'
import Footer from '../widgets/footer'
import SliderBanner from '../widgets/SliderBanner'
import FuncShow from '../widgets/func-show'
import Step from '../widgets/Step'
import FuncBanner from '../widgets/fun-banner'
import ContactFloat from '../widgets/contact-float.vue'

export default {
  components: {
    Navigator,
    FuncShow,
    ContactFloat,
    FuncBanner,
    SliderBanner,
    Step,
    Footer
  },
  data() {
    return {
      funcData: [
        {
          title: 'o2o鍟嗗绔熀纭€妯″潡',
          subTitle: 'o2o鍟嗗绔熀纭€妯″潡鏈夊簵閾虹被鍨嬨€佸簵閾虹被鍨嬪簵閾哄鏍搞€佸簵閾虹鐞嗐€佹姌鎵ｈ缃€佸晢鍝佺被鐩€佹墦鍗版満绠＄悊銆佹墦鍗版ā鏉裤€佸晢瀹跺垎璐︽槑缁嗐€佸垎璐﹀井淇¤处鍙疯缃瓑锛屾敮鎸佺數鑴憌eb鍜屾墜鏈虹锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?,
          carouseImg: require('@/assets/images/index/o2o-mall/banner2.png')
        },
        {
          title: 'o2o鍟嗗绔垎搴楃鐞?,
          subTitle: 'o2o鍟嗗绔垎搴楃鐞嗗彲浠ユ坊鍔犲垎搴楃殑鍩烘湰淇℃伅锛屽簵閾哄悕绉般€佸簵閾哄ご鍍忋€佹墍灞炲垎绫汇€佺湡瀹炲鍚嶃€佹墜鏈哄彿銆佸簵閾哄湴鍧€銆佹坊鍔犲簵閾烘姌鎵ｅ拰绠＄悊搴楅摵鐘舵€侊紝鎵归噺绠＄悊瑙ｉ櫎鍜屽垹闄わ紝鏀寔鐢佃剳web鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?,
          carouseImg: require('@/assets/images/index/o2o-mall/banner3.png')
        }
      ],
      stepInfo: {
        title: '鏃犻檺鐢熸垚鐙珛灏忕▼搴?,
        subTitle: '灏忕▼搴忎竴閿敓鎴愶紝涓€閿巿鏉冭幏鍙栨敮浠樺疂鎺堟潈锛屽嵆鍙墦鍖咃紝鏃犻渶寮€鍙戯紝鏅鸿幒灏忕▼搴忓姪鍔涘晢瀹舵祦閲忛攢閲忓弻澧為暱,鎻愪緵涓€鏁村绉佸煙杩愯惀鏂规.杞绘澗瀹炵幇鑾峰,杞寲,瑁傚彉,澶嶈喘!',
        stepList: [
          {
            normalImg:'',
            choiceImg: '',
            stepTitle: '',
            showImg: require('@/assets/images/index/o2o-mall/img1.png')
          }
        ]
      },
      slider: {
        image: require('@/assets/images/index/o2o-mall/phone.png'),
        title: 'O2O鍟嗗煄',
        desc: "鐙鏁板瓧闂ㄥ簵绯荤粺鏃犻檺鐢熸垚鐙珛灏忕▼搴忥紝鎵撻€犺嚜宸辩鍩熸祦閲 "
      },
      funInfo: {
        title: 'O2O鍟嗗煄鏉垮潡',
        list: [
          {
            title: '浼樻儬鍒告椿鍔?,
            img: require('@/assets/images/index/o2o-mall/img2.png'),
            text: '浼樻儬鍒告椿鍔ㄥ彲浠ヨ缃椿鍔ㄧ殑鏂规锛岀被鍨嬨€佷娇鐢ㄩ棬妲涖€佹敮浠樻弧棰濄€佸厤鍑忛銆佸彂閫佹暟閲忋€侀鍒镐汉璁剧疆銆侀鍙栧紶鏁版暟閲忛檺鍒躲€佷紭鎯犲埜鏈夋晥鏈熸椂闂村拰鍙娇鐢ㄧ殑鍟嗗搧锛屽彲鍙備笌瀹樻柟鐨勬椿鍔ㄦ垨鍙嚜瀹氫箟娲诲姩锛屾敮鎸佺數鑴憌eb鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?
          },
          {
            title: '绾㈠寘瑁傚彉',
            img: require('@/assets/images/index/o2o-mall/img3.png'),
            text: '瑁傚彉绾㈠寘涓撲负浼佷笟鍟嗘埛鎻愪緵绮惧噯瑁傚彉鑾峰鏂规,鐢ㄦ埛涓嶄粎鍙互閫氳繃鎵爜绾夸笅鍟嗗浜岀淮鐮佹秷璐归鍙栨敮浠樹紭鎯犵孩鍖?杩樺彲浠ラ€氳繃鍒嗕韩濂藉弸棰嗗彇娑堣垂绾㈠寘锛岃交鏉剧渷閽憋紝蹇€熷疄鐜拌鍙樺惛绮?璁╀紒涓氬晢鎴锋憜鑴变紶缁熻惀閿€妯″紡,瀹炵幇浣庢垚鏈鍙樺闀?鍔╁姏浼佷笟鍟嗘埛浣庢垚鏈珮鏁堣幏瀹紝鏀寔鐢佃剳web鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?
          },
          {
            title: '浼氬憳浣撶郴',
            img: require('@/assets/images/index/o2o-mall/img4.png'),
            text: '鏅鸿幒鎻愪緵瀹屾暣浼氬憳绠＄悊绯荤粺,甯姪鍟嗗杞绘澗鎼缓浼氬憳浣撶郴,閫氳繃浼氬憳鍗°€佷細鍛樼瓑绾с€佺Н鍒嗗晢鍩庛€佺鍒般€佷細鍛樺偍鍊笺€佷粯璐逛細鍛樼瓑鍔熻兘,杞绘澗绠＄悊浼氬憳瀹㈡埛,鍩瑰吇浼氬憳蹇犺瘹,璁╀細鍛樻寔缁椿璺冨拰澶嶈喘銆?
          },
          {
            title: '绉掓潃娲诲姩',
            img: require('@/assets/images/index/o2o-mall/img5.png'),
            text: '闄愭椂闄愰噺浣庝环鐨勪績閿€鏂瑰紡锛屽彲璁剧疆鎸囧畾鍟嗗搧鍦ㄦ寚瀹氭椂闂村唴浣庝环闄愰噺閿€鍞紝閫氳繃娲诲姩棰勭儹钀ラ€犳椿鍔ㄦ皵姘涳紝杩樺彲浠ュ紑鍚绾﹀鍔犲弬涓庨棬妲涳紝鏈€缁堣揪鍒板紩娴佽浆鍖栫殑鐩殑锛屾敮鎸佺數鑴憌eb鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?
          },
          {
            title: 'o2o鎺ㄦ墜',
            img: require('@/assets/images/index/o2o-mall/img6.png'),
            text: 'o2o鎺ㄦ墜閫傜敤浜庡晢瀹讹紝鍟嗗鎺ㄦ墜鐢熸椿锛屽皧浜秴澶氭潈鐩婄壒鏉冿紝鏈夊垎浜繑浣ｃ€佸皬搴楁柊寮€銆佽嚜涔拌繑浣ｃ€佷俊鐢ㄧ壒鏉冦€佸畬鍠勮祫鏂欍€佸叏鏂拌璁°€佷剑閲戞彁鐜般€佸紑搴楀鍔便€佽鍗曚剑閲戠瓑锛宱2o鎺ㄦ墜鏀寔鐢佃剳web鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?
          },
          {
            title: '灏忕▼搴忕偣椁?,
            img: require('@/assets/images/index/o2o-mall/img7.png'),
            text: '鐐归灏忕▼搴忓浜庨楗涓氭潵璇撮潪甯告湁瀹炵敤鎬?椤惧鍒板簵鏃犻渶鎺掗槦灏卞彲浠ヨ繘琛屾壂鐮佺偣椁?鑺傜害椤惧鎺掗槦鏃堕棿鐨勫悓鏃?涔熷噺灏忎簡椁愬巺鐨勪汉鍔涚墿鍔?澶уぇ鎻愬崌浜嗗伐浣滄晥鐜囥€?
          },
          {
            title: 'o2o鍟嗗绔憳宸ョ鐞?,
            img: require('@/assets/images/index/o2o-mall/img8.png'),
            text: 'o2o鍟嗗绔憳宸ョ鐞嗗彲浠ユ坊鍔犲憳宸ョ殑鍩烘湰淇℃伅锛屽鍚嶃€佸ご鍍忋€佽亴浣嶅悕绉般€佹搮闀挎妧鏈€佷粠涓氬勾鏁般€佸憳宸ュ姞鍏ユ椂闂淬€佺粦瀹氭墜鏈哄彿鍜岀櫥闄嗗瘑鐮侊紝绠＄悊鑱屼綅鏉冮檺锛屾壒閲忕鐞嗗仠鐢ㄥ拰鍒犻櫎锛屾敮鎸佺數鑴憌eb鍜屾墜鏈虹绠＄悊锛屾柟渚块棬搴椾笉鍚屽満鏅殑浣跨敤銆?
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
